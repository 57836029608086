import React from "react"
import { ThemeProvider } from "@creditas/stylitas"
import tokens from "@creditas/tokens"
import { Global } from "@creditas/global"

const customGlobal = {
  custom: `body {overflow: hidden}; li {list-style: none;}`,
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={{ ...tokens }}>
    <Global {...customGlobal} />
    {element}
  </ThemeProvider>
)
